export const saludo = {
	remoto : "Bienvenido a nuestro servicio de Teleorientación",
	sala: "Bienvenido al módulo de preadmisión"
}

export const recomendaciones = {
	remoto : `<div class="text-h5 mt-5 secondary--text mt-10 font-weight-bold ">Le informamos que estamos implementando una prueba piloto de un nuevo sistema para optimizar su atención. No modifica en absoluto el flujo de atención actual.</div>`,
	sala: `<div class="text-h5 mt-5 secondary--text mt-10 font-weight-bold ">Le informamos que estamos implementando una prueba piloto de un nuevo sistema para optimizar su atención. No modifica en absoluto el flujo de atención actual.</div>`
}

export const audioDescriptionMessages = {
	welcome_sala: "Bienvenido al módulo de preadmisión. Le informamos que estamos implementando una prueba piloto de un nuevo sistema para optimizar su atención. No modifica en absoluto el flujo de atención actual",
	welcome_default: "Bienvenido a nuestro servicio de Teleorientación. Ten en cuenta que: Esta herramienta es orientativa y busca optimizar procesos de admisión y acceso a los servicios de salud. Se requiere veracidad y exactitud en los datos. Por favor, ingresa tú número de identificación para iniciar.",
	paciente_datos:"Bienvenido a nuestro servicio de Teleorientación. Por favor, verifica y completa la siguiente información para iniciar.",
	accept: "Acepto la política de tratamiento de datos de " + process.env.VUE_APP_CLIENTE_NAME,
}

export const mensajesPro = {
	emergency_ambulance:{text: "<div class='text-justify black--text'><div class='red--text mb-3'>Según los síntomas reportados usted presenta una <b>emergencia médica</b>, debe acudir <b>inmediatamente</b> a urgencias.</div>", icon:"ambulance", level: "error", color:"red", triage: 1, name:"Reanimación", todo: 'Direccionar a servicio de urgencias'},
	emergency:{text: "<div class='text-justify black--text'><div class='red--text mb-3'>Según los síntomas reportados usted presenta una <b>emergencia médica</b>, debe acudir <b>rápidamente</b> a urgencias.</div>", icon:"car", level: "error", color:"red", triage: 2, name:"Emergencia", todo: 'Direccionar a servicio de urgencias'},
	consultation_24:{text: "<div class='text-justify black--text'><div class='deep-orange--text mb-3'>Según los síntomas reportados usted presenta una <b>consulta médica</b>, dentro de las siguientes <b>24 horas</b> puede dirigirse a urgencias.</div>", icon:"hospital-user", level: "warning", color:"deep-orange", triage: 3, name:"Urgencia", todo: 'Direccionar a servicio de urgencias'},
	consultation:{text: "<div class='text-justify black--text'><div class='blue--text mb-3'>De acuerdo a los síntomas reportados, le recomendamos solicitar una consulta médica prioritaria.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 4, name:"Prioritario", todo: 'Teleconsulta prioritaria'},
	self_care:{text: "<div class='text-justify black--text'><div class='blue--text mb-3'>De acuerdo a los síntomas reportados, le recomendamos solicitar una consulta médica prioritaria.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 5, name:"No urgente", todo: 'Autocuidado/seguimiento'}
}

export const mensajesSalida = {
	sala: {
		1:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos todos los procesos para facilitar tu atención en urgencias.</div><div class='primary--text mb-3 text-h4'>Queda atento al llamado.</div><div class='primary--text mb-3 text-h4'>Recuerda que puedes utilizar nuestros servicios de Teleorientación de FCV Digital en nuestra página web antes de venir.</div>", icon:"ambulance", level: "error", color:"red", triage: 1, name:"Reanimación"},
		2:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos todos los procesos para facilitar tu atención en urgencias.</div><div class='primary--text mb-3 text-h4'>Queda atento al llamado.</div><div class='primary--text mb-3 text-h4'>Recuerda que puedes utilizar nuestros servicios de Teleorientación de FCV Digital en nuestra página web antes de venir.</div>", icon:"car", level: "error", color:"red", triage: 2, name:"Emergencia"},
		3:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos todos los procesos para facilitar tu atención en urgencias.</div><div class='primary--text mb-3 text-h4'>Queda atento al llamado.</div><div class='primary--text mb-3 text-h4'>Recuerda que puedes utilizar nuestros servicios de Teleorientación de FCV Digital en nuestra página web antes de venir.</div>", icon:"hospital-user", level: "warning", color:"deep-orange", triage: 3, name:"Urgencia"},
		4:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos todos los procesos para facilitar tu atención.</div><div class='primary--text mb-3 text-h4'>Queda atento al llamado.</div><div class='primary--text mb-3 text-h4'>Recuerda que puedes utilizar nuestros servicios de Teleorientación de FCV Digital en nuestra página web antes de venir.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 4, name:"Prioritario"},
		5:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos todos los procesos para facilitar tu atención.</div><div class='primary--text mb-3 text-h4'>Queda atento al llamado.</div><div class='primary--text mb-3 text-h4'>Recuerda que puedes utilizar nuestros servicios de Teleorientación de FCV Digital en nuestra página web antes de venir.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 5, name:"No urgente"}
	},
	remoto: {
		1:{text: "<div class='text-justify black--text'><div class='red--text mb-3'>Según los síntomas reportados usted presenta una posible urgencia médica, debe acudir rápidamente a urgencias.</div><div class='red--text mb-3'>Uno de nuestros asesores se comunicará con usted en el menor tiempo posible.</div>", icon:"ambulance", level: "error", color:"red", triage: 1, name:"Reanimación", todo: 'Direccionar a servicio de urgencias'},
		2:{text: "<div class='text-justify black--text'><div class='red--text mb-3'>Según los síntomas reportados usted presenta una posible urgencia médica, debe acudir rápidamente a urgencias.</div><div class='red--text mb-3'>Uno de nuestros asesores se comunicará con usted en el menor tiempo posible.</div>", icon:"car", level: "error", color:"red", triage: 2, name:"Emergencia", todo: 'Direccionar a servicio de urgencias'},
		3:{text: "<div class='text-justify black--text'><div class='deep-orange--text mb-3'>En momentos, un agente del servicio lo contactará para orientarlo sobre los pasos a seguir.</div><div class='deep-orange--text mb-3'>Gracias por hacer uso de esta función y por apoyar el uso racional de los servicios de urgencias.</div>", icon:"hospital-user", level: "warning", color:"deep-orange", triage: 3, name:"Urgencia", todo: 'Direccionar a servicio de urgencias'},
		4:{text: "<div class='text-justify black--text'><div class='blue--text mb-3'>De acuerdo a los síntomas reportados, le recomendamos solicitar una consulta médica prioritaria.</div><div class='blue--text mb-3'>Uno de nuestros asesores se comunicará con usted en el menor tiempo posible.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 4, name:"Prioritario", todo: 'Teleconsulta prioritaria'},
		5:{text: "<div class='text-justify black--text'><div class='blue--text mb-3'>De acuerdo a los síntomas reportados, le recomendamos solicitar una consulta médica prioritaria.</div><div class='blue--text mb-3'>Uno de nuestros asesores se comunicará con usted en el menor tiempo posible.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 5, name:"No urgente", todo: 'Autocuidado/seguimiento'}
	},
	signosVitales: {
		1:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos tu proceso de teleorientación.</div>", icon:"ambulance", level: "error", color:"red", triage: 1, name:"Reanimación"},
		2:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos tu proceso de teleorientación.</div>", icon:"car", level: "error", color:"red", triage: 2, name:"Emergencia"},
		3:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos tu proceso de teleorientación.</div>", icon:"hospital-user", level: "warning", color:"deep-orange", triage: 3, name:"Urgencia"},
		4:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos tu proceso de teleorientación.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 4, name:"Prioritario"},
		5:{text: "<div class='primary--text mb-3 text-h4'>Gracias, iniciaremos tu proceso de teleorientación.</div>", icon:"user-md-chat", level: "info", color:"blue", triage: 5, name:"No urgente"}
	},
}
